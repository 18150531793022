import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingTypography from '../typography/LoadingTypography';

const StatisticItemStack = ({ title, value, valueProps, loading }) => (
  <Stack spacing={0.5}>
    <Typography fontWeight="bold" variant="body1">
      {title}
    </Typography>
    <LoadingTypography variant="body1" loading={loading} {...valueProps}>
      {value}
    </LoadingTypography>
  </Stack>
);

export default StatisticItemStack;

StatisticItemStack.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.node,
  valueProps: PropTypes.func,
  loading: PropTypes.bool,
};

StatisticItemStack.defaultProps = {
  value: '-',
  valueProps: {},
  loading: false,
};
