import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NationalTaxServiceLogoAndText = ({ width, text }) => {
  const { t } = useTranslation();
  return (
    <Stack
      width={width}
      alignItems="center"
      height="auto"
      spacing={2}
      justifyContent="flex-end"
    >
      <Icon sx={{ height: 62, width: '100%' }}>
        <img src={t('National Tax Service logo')} style={{ height: '100%' }} alt="sii" />
      </Icon>
      {text && (
      <Typography variant="body1" align="center" width="100%">
        {text}
      </Typography>
      )}
    </Stack>
  );
};

NationalTaxServiceLogoAndText.propTypes = {
  width: PropTypes.string,
  text: PropTypes.string,
};

NationalTaxServiceLogoAndText.defaultProps = {
  width: '100%',
  text: 'Te pedimos estos datos para mostrarte toda la información asociada a tus facturas',
};

export default NationalTaxServiceLogoAndText;
