import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';

const ControlledMultipleSelect = ({ id, value, onChange, label, options }) => (
  <Stack direction="column">
    <Typography variant="formLabel">{label}:</Typography>
    <FormControl variant="standard">
      <InputLabel id={`${id}-select-label`} />
      <Select
        labelId={`${id}-select-label`}
        id={`${id}-select`}
        multiple
        value={value}
        onChange={({ target: { value: newValue } }) => onChange(typeof newValue === 'string' ? newValue.split(',') : newValue)}
        label={label}
        input={<OutlinedInput variant="form" />}
      >
        {options.map(({ label: optionLabel, value: optionValue, disabled }) => (
          <MenuItem key={optionLabel} value={optionValue} disabled={disabled}>
            {optionLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Stack>
);

ControlledMultipleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
    }),
  ).isRequired,
};

export default ControlledMultipleSelect;
