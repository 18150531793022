export const stringOrderByToSortModel = (stringOrderBy) => {
  if (!stringOrderBy) return [];
  const field = stringOrderBy?.charAt?.(0) === '-' ? stringOrderBy.slice(1) : stringOrderBy;
  const sort = stringOrderBy?.charAt?.(0) === '-' ? 'desc' : 'asc';
  return field.length ? [{ sort, field }] : [];
};
export const sortModelToString = (givenSortModel) => {
  if (!givenSortModel.length) return '';
  const { field, sort } = givenSortModel[0];
  const prefix = sort === 'asc' ? '' : '-';
  return prefix + field;
};
