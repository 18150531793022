import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useIsMobile } from '../../hooks';

const LastUpdatedDate = ({ lastUpdated }) => {
  const isMobile = useIsMobile();
  if (isMobile) return null;
  return (
    <Typography
      variant="subtitle1"
      sx={{ display: 'flex', flexDirection: 'column', marginLeft: '8px', textAlign: 'left', minWidth: { md: 0, lg: 130 } }}
    >
      Panel actualizado al:
      <Box> {lastUpdated}</Box>
    </Typography>
  );
};

LastUpdatedDate.propTypes = {
  lastUpdated: PropTypes.string.isRequired,
};

export default LastUpdatedDate;
