import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

const ConditionalBorderStack = ({ id, showBorder, onConditionalClick, children }) => (
  <Stack
    id={id}
    sx={{
      borderRadius: 4,
      border: '1.5px solid',
      borderColor: showBorder ? 'error.dark' : 'divider',
      cursor: 'pointer',
    }}
    onClick={onConditionalClick}
  >
    {children}
  </Stack>
);

ConditionalBorderStack.propTypes = {
  id: PropTypes.string.isRequired,
  showBorder: PropTypes.bool.isRequired,
  onConditionalClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ConditionalBorderStack.defaultProps = {
  onConditionalClick: null,
};

export default ConditionalBorderStack;
