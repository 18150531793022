import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

const OkOperation = ({ toggleMenu }) => (
  <Grid container justifyContent="center" direction="column" alignItems="center">
    <Typography variant="h2" align="center" gutterBottom paragraph fontWeight="600" width="100%">
      ¡Listo!
    </Typography>
    <Typography variant="h6" gutterBottom paragraph align="center" width="100%">
      Te notificaremos cuando se realice el giro.
    </Typography>
    <TaskAltOutlinedIcon fontSize="170px" sx={{ color: 'gray.main', fontSize: '170px' }} />
    { toggleMenu && (
      <Button variant="contained" color="primary" size="small" onClick={toggleMenu} sx={{ mt: 2, width: 80 }} id="cart-ok-operation">
        OK
      </Button>
    )}
  </Grid>
);

OkOperation.propTypes = {
  toggleMenu: PropTypes.func,
};

OkOperation.defaultProps = {
  toggleMenu: null,
};

export default OkOperation;
