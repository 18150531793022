import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

const IconButtonsStack = ({ items }) => (
  <Stack direction="row">
    {items.map(({ id, tooltipTitle, disabled, color, onClick, icon }) => (
      <Tooltip title={tooltipTitle} key={id}>
        <span>
          <IconButton
            id={id}
            disabled={disabled}
            sx={{ color, '&.Mui-disabled': { color } }}
            onClick={onClick || (() => {})}
          >
            {icon}
          </IconButton>
        </span>
      </Tooltip>
    ))}
  </Stack>
);

IconButtonsStack.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      tooltipTitle: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
      color: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      icon: PropTypes.element.isRequired,
    }).isRequired,
  ).isRequired,
};

export default IconButtonsStack;
