import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomOutlinedInput = styled(OutlinedInput)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D4D4D4',
    borderWidth: '0.5px',
    borderRadius: '8px',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D4D4D4',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D4D4D4',
  },
  '& .MuiSelect-icon': {
    color: '#E62E66',
  },
});

const ControlledSelect = ({ value, handleChange, label, options, disabled }) => (
  <Stack direction="column" sx={{ maxWidth: '400px', width: '100%', mt: 2 }}>
    {label && (
      <Typography variant="formLabel">{label}:</Typography>
    )}
    <FormControl variant="outlined" sx={{ width: '100%' }} disabled={disabled}>
      <InputLabel id={`${value}-select-label`} />
      <Select
        labelId={`${value}-select-label`}
        id={`${value}-select`}
        value={value || ''}
        onChange={handleChange}
        label={value}
        input={<CustomOutlinedInput />}
      >
        {options.map(({ label: optionLabel, value: optionValue, disabled: optionDisabled }) => (
          <MenuItem key={optionLabel} value={optionValue} disabled={optionDisabled}>
            {optionLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Stack>
);

ControlledSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
};

ControlledSelect.defaultProps = {
  value: '',
  disabled: false,
};

export default ControlledSelect;
