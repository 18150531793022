import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const FingoTableHead = (props) => {
  const {
    columns,
    checkboxSelection,
    collapsible,
    sortModel,
    onSortModelChange,
  } = props;

  const sortDirection = useMemo(() => sortModel[0]?.sort, [sortModel]);

  const sortClickHandler = (headerName) => {
    const sort = headerName === sortModel[0]?.field && sortModel[0]?.sort === 'asc' ? 'desc' : 'asc';
    onSortModelChange([{ field: headerName, sort }]);
  };

  return (
    <TableHead>
      <TableRow sx={{ height: 10 }}>
        {((checkboxSelection || collapsible)) && <TableCell padding="checkbox" />}
        {columns.map((column) => (
          <TableCell
            key={column.field}
            sortDirection={column.sortable && sortDirection}
          >
            <TableSortLabel
              active={column.sortable && !!sortModel[0]?.field?.includes(column.field)}
              hideSortIcon={!column.sortable}
              direction={sortDirection}
              onClick={() => column.sortable && sortClickHandler(column.field)}
              sx={{ display: 'flex', justifyContent: column.headerAlign || 'left' }}
            >
              {column.headerName}
              {column.sortable && !!sortModel[0]?.field?.includes(column.field) && (
                <Box component="span" sx={visuallyHidden} fontSize={6}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

FingoTableHead.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string,
      sortable: PropTypes.bool,
      renderCell: PropTypes.func,
      valueGetter: PropTypes.func,
    }),
  ).isRequired,
  checkboxSelection: PropTypes.bool.isRequired,
  collapsible: PropTypes.bool.isRequired,
  sortModel: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      sort: PropTypes.string,
    }),
  ).isRequired,
  onSortModelChange: PropTypes.func.isRequired,
};

export default FingoTableHead;
