import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import FingoTableRow from './FingoTableRow';
import FingoTableLoader from './FingoTableLoader';

const FingoTableBody = (props) => {
  const {
    columns,
    rows,
    loading,
    checkboxSelection,
    isRowSelectable,
    selectionModel,
    onSelectionModelChange,
    collapsible,
    isRowCollapsible,
    collapseComponent,
    initialExpandedRow,
  } = props;

  const noRowsCollspan = useMemo(
    () => ((checkboxSelection || collapsible) ? columns.length + 1 : columns.length),
    [checkboxSelection, collapsible, columns],
  );

  if (loading) {
    return (
      <TableBody>
        <TableRow hover role="checkbox" tabIndex={-1}>
          <TableCell colSpan={noRowsCollspan}>
            <FingoTableLoader />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {rows.map((row) => (
        <FingoTableRow
          key={row.id}
          columns={columns}
          row={row}
          checkboxSelection={checkboxSelection}
          isRowSelectable={isRowSelectable}
          selectionModel={selectionModel}
          onSelectionModelChange={onSelectionModelChange}
          collapsible={collapsible}
          isRowCollapsible={isRowCollapsible}
          collapseComponent={collapseComponent}
          initialExpandedRow={initialExpandedRow}
        />
      ))}
    </TableBody>
  );
};

FingoTableBody.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string,
      sortable: PropTypes.bool,
      renderCell: PropTypes.func,
      valueGetter: PropTypes.func,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  checkboxSelection: PropTypes.bool.isRequired,
  isRowSelectable: PropTypes.func.isRequired,
  selectionModel: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectionModelChange: PropTypes.func.isRequired,
  collapsible: PropTypes.bool.isRequired,
  isRowCollapsible: PropTypes.func.isRequired,
  collapseComponent: PropTypes.func.isRequired,
  initialExpandedRow: PropTypes.string,
};

FingoTableBody.defaultProps = {
  initialExpandedRow: null,
};

export default FingoTableBody;
