/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

export const TextTermSection = ({ id, text, sectionType }) => {
  const theme = useTheme();

  return (
    <Typography
      paragraph
      sx={{
        width: '100%',
        mt: sectionType === 'subtitle' ? 2 : 1,
        fontWeight: sectionType === 'subtitle' ? 'bold' : 'normal',
        color: sectionType === 'subtitle' ? theme.palette.primary.main : 'inherit',
      }}
      align="justify"
      key={id}
    >
      {text}
    </Typography>
  );
};

TextTermSection.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
  sectionType: PropTypes.string.isRequired,
};
