import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const SelectRadioGroup = ({
  options,
  setOption,
  selectedOption,
  setMoreInputs,
  moreInputs,
}) => {
  const optionSetter = useCallback(({ target: { value } }) => setOption(value), []);
  return (
    <RadioGroup
      aria-label="collection-action-type"
      value={selectedOption}
      onChange={optionSetter}
    >
      {options.map(({ label, value, MoreInputsComponent }) => (
        <>
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio />}
            label={label}
            sx={{ height: '35px' }}
          />
          {MoreInputsComponent && selectedOption === value && (
            <MoreInputsComponent setMoreInputs={setMoreInputs} moreInputs={moreInputs} />
          )}
        </>
      ))}
    </RadioGroup>
  );
};

SelectRadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
  setOption: PropTypes.func.isRequired,
  setMoreInputs: PropTypes.func,
  selectedOption: PropTypes.string,
  moreInputs: PropTypes.string,
};

SelectRadioGroup.defaultProps = {
  selectedOption: '',
  moreInputs: '',
  setMoreInputs: () => null,
};

export default SelectRadioGroup;
