import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import FingoTable from '../tables/FingoTable';
import { AMORTIZATION_SCHEDULE } from '../../graphql/payment_plans/query';
import { useAmortizationScheduleColumns } from '../../constants';
import Installments from '../paymentPlans';
import FingoMainView from '../layout/FingoMainView';

const ClientPaymentPlanInformation = ({ masterEntity, onDataLoaded }) => {
  const [initialExpandedRow, setInitialExpandedRow] = useState(null);

  return (
    <FingoMainView
      id="client-payment-plan"
      query={AMORTIZATION_SCHEDULE}
      queryCustomVariables={{
        masterEntity_Id: masterEntity?.graphqlId,
      }}
      hideReloadButton
      onCompletedSetRows={(data) => {
        onDataLoaded(data);
        const rows = Object.values(data)[0].edges.map((edge) => edge.node);
        if (rows.length > 0) {
          setInitialExpandedRow(rows[0].id);
        }
        return rows;
      }}
      slots={{
        table: FingoTable,
      }}
      slotProps={{
        table: {
          columns: useAmortizationScheduleColumns(),
          includeHeaders: [
            'id',
            'masterEntity_Name',
            'interestRate',
            'defaultRate',
            'contentType_Model',
            'createdAt',
          ],
          noRowsMessage: () => <Typography>No hay planes de pago</Typography>,
          initialOrderBy: '-createdAt',
          checkboxSelection: false,
          rowsPerPageOptions: [10, 25, 50, 100],
          collapsible: true,
          isRowCollapsible: ({ row }) => !!row,
          collapseComponent: Installments,
          initialExpandedRow, // Pasar el estado inicial del row expandido
        },
      }}
    />
  );
};

ClientPaymentPlanInformation.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    graphqlId: PropTypes.string.isRequired,
  }).isRequired,
  onDataLoaded: PropTypes.func.isRequired,
};

export default ClientPaymentPlanInformation;
