import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { numberToMoney } from '../../helpers';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 9,
  borderRadius: 5,
  flexGrow: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'primary',
  },
}));

const CreditLineLinearProgress = ({ creditLine }) => {
  const rate = creditLine ? creditLine.creditUsed / creditLine.limitAmount : 0;
  const value = Math.min(rate, 1);
  const currency = creditLine?.currency?.isoCode;

  const creditUsed = creditLine
    ? `Monto Utilizado: ${numberToMoney(creditLine.creditUsed, currency)}`
    : 'Empresa sin Linea de Crédito';

  const availableAmount = creditLine?.limitAmount ? Math.max(creditLine.limitAmount
     - creditLine.creditUsed, 0) : null;

  const lineAmount = creditLine?.limitAmount || null;

  const totalAmount = creditLine?.limitAmount
    ? `Monto Disponible: ${numberToMoney(availableAmount, currency)}`
    : 'Empresa sin Linea de Crédito';

  const lineAmountText = lineAmount ? `${numberToMoney(lineAmount, currency)}` : null;

  return (
    <Stack direction="row" spacing={1}>
      <Stack direction="column" spacing={1}>
        <Tooltip title={creditUsed}>
          <BorderLinearProgress variant="determinate" value={value * 100} />
        </Tooltip>
        <Typography noWrap sx={{ fontSize: 11 }}>{totalAmount}</Typography>
      </Stack>
      <Typography noWrap sx={{ fontSize: 11 }}>{lineAmountText}</Typography>
    </Stack>
  );
};

CreditLineLinearProgress.propTypes = {
  creditLine: PropTypes.shape({
    creditUsed: PropTypes.number,
    limitAmount: PropTypes.number,
    currency: PropTypes.shape({
      isoCode: PropTypes.string,
    }),
  }),
};

CreditLineLinearProgress.defaultProps = {
  creditLine: null,
};

export default CreditLineLinearProgress;
