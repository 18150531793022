import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const KeyValueStack = ({ items }) => (
  <Stack direction="row" spacing="2vw">
    {items?.map(({ key, value }) => (
      <Stack key={key} spacing={1} direction="column">
        <Typography variant="body1">{key}</Typography>
        <Typography variant="info">{value}</Typography>
      </Stack>
    ))}
  </Stack>
);

KeyValueStack.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default KeyValueStack;
